import { graphql } from 'gatsby';
import React from 'react';
import { FooterBoxContent } from '../../../components/layout/Footer';
import Layout from '../../../components/layout/Layout';
import ContentSection from '../../../pages-components/resources/ContentSection';
import IntroSection from '../../../pages-components/resources/IntroSection';
import ResearchTabContent from '../../../pages-components/resources/TabsContent/ResearchTabContent';

export interface ResourcesPageProps {
  data: {
    allSanityResources: {
      nodes: Publication[];
    };
    allSanityYears: {
      nodes: Year[];
    };
  };
  pageContext: {
    year: number;
  };
  location: {
    search: string;
    pathname: string;
  };
}

export type Publication = {
  id: string;
  date: string;
  title: string;
  url: string;
  file: {
    asset: {
      url: string;
    };
  };
  image: {
    asset: {
      url: string;
    };
  };
};

export type Year = {
  year: number;
};

export const queryGraphql = graphql`
  query GetAllResources($gte: Date, $lte: Date) {
    allSanityYears {
      nodes {
        year
      }
    }
    allSanityResources(
      filter: { date: { gte: $gte, lte: $lte } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        date
        title
        url
        image {
          asset {
            url
          }
        }
        file {
          asset {
            url
          }
        }
      }
    }
  }
`;

const ResourcesPage = (props: ResourcesPageProps): JSX.Element => {
  const data = props.data.allSanityResources.nodes;
  const years = props.data.allSanityYears.nodes.sort(
    (a: Year, b: Year) => b.year - a.year,
  );
  const selectedYear = props.pageContext.year;

  return (
    <Layout
      description="Research is the foundation of our work in developing antibody analytics solutions. Discover our work with leading academic and industrial partners."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Resources | Antibody Engineering Insights"
    >
      <IntroSection />
      <ContentSection path={props.location.pathname} />
      <ResearchTabContent
        data={data}
        selectedYear={selectedYear}
        years={years}
      />
      {/* <NewsletterSection /> */}
    </Layout>
  );
};

export default ResourcesPage;
