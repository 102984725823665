import React from 'react';
import styled, { css } from 'styled-components';
import {
  Header3,
  Upheader,
} from '../../../components/atoms/Typography/Typography';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
} from '../../../styles/mediaQueries';
import { format } from 'date-fns';
import { useState } from 'react';
import { Pagination } from '../../../components/molecules/Pagination/Pagination';
import { ExternalLink } from '../../../components/atoms/Link';
import DownloadIcon from '../../../assets/images/Download.svg';
import ExpandIcon from '../../../assets/images/Expand.svg';
import { Publication, Year } from '../../../pages/resources/year';

export interface ResearchTabContentProps {
  data: Publication[];
  selectedYear: number;
  years: Year[];
}

const formatValue = 'dd LLLL, yyyy';
const defaultPage = 0;
const limit = 10;

export const ResearchTabContent = ({
  data,
  selectedYear,
  years,
}: ResearchTabContentProps): JSX.Element => {
  const [page, setPage] = useState(defaultPage);

  const offset = page * limit;

  if (!data || data.length === 0) {
    return (
      <EmptyPage>
        <CardTitle>Sorry, we don&apos;t have any publications</CardTitle>
      </EmptyPage>
    );
  }

  return (
    <ResearchTabWrapper key={selectedYear}>
      <ContentWrapper>
        <DatesPublications>
          {years.map((year) => (
            <DatesPublicationsItem
              href={`/resources/year/${year.year}`}
              isActive={year.year === selectedYear}
              key={year.year}
            >
              {year.year}
            </DatesPublicationsItem>
          ))}
        </DatesPublications>
        <TableHeadersWrapper>
          <Upheader $color="delicateAccented">SOURCE</Upheader>
          <Upheader $color="delicateAccented">DATE & TITLE</Upheader>
        </TableHeadersWrapper>
        {data
          .slice(offset, offset + limit)
          .map(({ date, id, title, url, image, file }) => (
            <PublicationCard key={id}>
              <LogoWrapper>
                <Image src={image.asset.url} />
              </LogoWrapper>
              <TitleWrapper>
                <Upheader $color="delicateAccented">
                  {format(new Date(date), formatValue)}
                </Upheader>
                <CardHeader>
                  <CardTitle>{title}</CardTitle>
                </CardHeader>
              </TitleWrapper>
              <DownloadSection>
                {url !== null && (
                  <div>
                    <ArticleLink $color="tertiary" href={url}>
                      <Image src={ExpandIcon} />
                      Article
                    </ArticleLink>
                  </div>
                )}

                {file !== null && (
                  <div>
                    <DownloadLink
                      $color="tertiary"
                      download
                      href={file.asset.url}
                    >
                      <Image src={DownloadIcon} />
                      Download
                    </DownloadLink>
                  </div>
                )}
              </DownloadSection>
            </PublicationCard>
          ))}
        {data.length > 0 && (
          <PaginationWrapper>
            <Pagination
              itemsCount={data.length}
              itemsPerPage={limit}
              onChange={(page) => setPage(page)}
              page={page}
            />
          </PaginationWrapper>
        )}
      </ContentWrapper>
    </ResearchTabWrapper>
  );
};

const ResearchTabWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding-bottom: 20rem;

  ${forScreenAtMost800pxWide(css`
    padding: 0 1rem 30rem 1rem;
  `)}
`;

const ContentWrapper = styled.div`
  max-width: 1134px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
`;

const DatesPublications = styled.div`
  display: flex;
  gap: 30px;
`;

const DownloadSection = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin-left: 30px;

  ${forScreenAtMost800pxWide(css`
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    width: fit-content;
  `)}
`;

const ArticleLink = styled(ExternalLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: ${({ theme }) => theme.fontSizes.forms.input};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.2em;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.texts.tertiary};
  }
`;

const DownloadLink = styled(ArticleLink)``;

const DatesPublicationsItem = styled.a<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colors.texts.delicate};
  font-size: ${({ theme }) => theme.fontSizes.abGraph.counter};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
      border-bottom: 2px solid
        ${({ theme }) => theme.colors.texts.activeMenuLink};
    `}

  &:hover {
    cursor: pointer;
  }
`;

const TableHeadersWrapper = styled.div`
  display: grid;
  grid-template-columns: 278px 1fr;
  padding: 0 23px;

  ${forScreenAtMost800pxWide(css`
    display: none;
  `)}
`;

const PublicationCard = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.secondaryAccented};
  padding: 23px;
  position: relative;
  display: grid;
  grid-template-columns: 268px 1fr 10px 1fr;
  gap: 10px;

  &:first-of-type {
    margin-top: 23px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  ${forScreenAtMost800pxWide(css`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `)}
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const CardTitle = styled(Header3).attrs({ $color: 'tertiary' })`
  max-width: 390px;

  ${forScreenAtMost680pxWide(css`
    max-width: unset;
  `)}
`;

const LogoWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  max-height: 45px;
  display: flex;
  justify-content: flex-start;

  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Image = styled.img``;

const EmptyPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default ResearchTabContent;
