import React, { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

export const Pagination = ({
  page,
  itemsPerPage,
  itemsCount,
  onChange,
}: {
  page: number;
  itemsPerPage: number;
  itemsCount: number;
  onChange: (page: number) => void;
}) => {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);
  const handlePageClick = useCallback(
    ({ selected }: { selected: number }) => {
      onChange(selected);
    },
    [onChange],
  );

  return (
    <PaginationWrapper>
      <ReactPaginate
        activeClassName="active"
        breakClassName="separator"
        breakLabel="..."
        disableInitialCallback={true}
        forcePage={page}
        marginPagesDisplayed={1}
        nextLinkClassName="hidden"
        onPageChange={handlePageClick}
        pageClassName="step"
        pageCount={pageCount}
        pageRangeDisplayed={3}
        previousLinkClassName="hidden"
        renderOnZeroPageCount={() => null}
      />
    </PaginationWrapper>
  );
};

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  > ul {
    display: flex;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;

    .step {
      width: 3.125rem;
      height: 3.125rem;
      border: 1px solid ${({ theme }) => theme.colors.texts.delicate};
      background-color: ${({ theme }) => theme.colors.texts.primary};
      margin-right: 0.375rem;
      color: ${({ theme }) => theme.colors.texts.delicate};
      font-size: ${({ theme }) => theme.fontSizes.footer.firstLine};

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: ${({ theme }) =>
          theme.colors.backgrounds.secondaryPair};
        color: ${({ theme }) => theme.colors.backgrounds.focus};
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .hidden {
      display: none;
    }

    .separator {
      margin: 0 0.625rem 0 0.25rem;
      line-height: 3.125rem;
    }
  }
`;

export const RouterPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
  align-items: flex-end;
`;
